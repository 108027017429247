import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { IndustriesList } from "../general";
import AttorneyModal from "../modals/AttorneyModal";
import I18n from "../../../../utils/i18n";

const CardLabel = ({ label, value }) => (
    <>
        <small className="text-muted">{label}</small>
        <div>{value}</div>
    </>
);

const AttorneyCard = ({ attorney }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Card className="mb-4">
            <Card.Body>
                <div>
                    <strong onClick={() => setShowModal(true)}>{attorney.name}</strong>
                </div>

                <div>
                    <small className="text-muted">{attorney.title}</small>
                </div>

                <Row className="mt-2">
                    <Col sm={4}>
                        <CardLabel
                            label={I18n.t("activerecord.attributes.legal/attorney.linkedin_url")}
                            value={
                                <>
                                    {attorney.linkedin_url && (
                                        <a href={attorney.linkedin_url}>{I18n.t("sidebar.links.profile.title")}</a>
                                    )}
                                </>
                            }
                        />
                    </Col>
                    <Col sm={4}>
                        <CardLabel label={I18n.t("activerecord.attributes.user.phone")} value={attorney.phone} />
                    </Col>
                    <Col sm={4}>
                        <CardLabel label={I18n.t("diversity.clients.steps.firm_email")} value={attorney.email} />
                    </Col>
                </Row>

                {attorney.practice_areas.length > 0 && (
                    <Row>
                        <Col>
                            <IndustriesList
                                industries={attorney.practice_areas}
                                showMoreKey="common.links.show_without_more"
                                name={I18n.t("activerecord.attributes.legal/attorney.practice_area_name")}
                            />
                        </Col>
                    </Row>
                )}
            </Card.Body>

            <AttorneyModal attorneyUid={attorney.uid} handleClose={() => setShowModal(false)} show={showModal} />
        </Card>
    );
};

CardLabel.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

AttorneyCard.propTypes = {
    attorney: PropTypes.shape({
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        linkedin_url: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        practice_areas: PropTypes.array,
        uid: PropTypes.string.isRequired,
    }).isRequired,
};

export default AttorneyCard;
