import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import classNames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import _isEmpty from "lodash/isEmpty";
import _isUndefined from "lodash/isUndefined";
import I18n from "../../../utils/i18n";
import { getCountries, getCountryStates } from "../../../api/countriesApi";
import { getLocationTypes } from "../../../api/locationTypesApi";
import { setAlert } from "../../../state/alert/alertActions";
import NavigationPrompt from "../NavigationPrompt";
import NewPageTitle from "../NewPageTitle";
import EditPageTitle from "../EditPageTitle";
import IndustrySection from "../OrganizationForm/sections/IndustrySection";
import CopyIndustryFromOrganization from "../CopyIndustryFromOrganization";

const LocationForm = ({ organizationUid, initialValues, createOrUpdateOrganizationLocation, fallback }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [locationTypes, setLocationTypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(initialValues.country);
    const [states, setStates] = useState([]);
    const [statesLoading, setStatesLoading] = useState(false);

    useEffect(() => {
        getLocationTypes(organizationUid).then((response) => setLocationTypes(response.data));
    }, []);

    useEffect(() => {
        getCountries().then((response) => setCountries(response.data));
    }, []);

    useEffect(() => {
        if (!_isEmpty(country)) {
            setStatesLoading(true);

            getCountryStates(country)
                .then((response) => setStates(response.data))
                .finally(() => setStatesLoading(false));
        }
    }, [country]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(100).required(),
        location_type_id: Yup.number().required().nullable(),
        address_line1: Yup.string().max(200).nullable(),
        address_line2: Yup.string().max(100).nullable(),
        city: Yup.string().max(100).nullable(),
        zip_code: Yup.string().max(20).nullable(),
        state: Yup.string()
            .max(3)
            .nullable()
            .when("country", (_value, schema) => (states.length === 0 ? schema : schema.required())),
        country: Yup.string().required().max(2).nullable(),
        phone: Yup.string().max(100).nullable(),
        email: Yup.string().email().max(100).nullable(),
        industry_relations_attributes: Yup.array()
            .of(
                Yup.object().shape({
                    primary: Yup.boolean().required(),
                    industry_id: Yup.string().required(),
                }),
            )
            .uniqueProperty("industry_id", I18n.t("errors.messages.taken"))
            .test(
                "at_least_one_primary",
                I18n.t("errors.messages.blank"),
                (items) => items.length === 0 || items.some((item) => item.primary === true),
            ),
    });

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        createOrUpdateOrganizationLocation(organizationUid, { location: values })
            .then(({ data }) => {
                if (fallback) {
                    setSubmitting(false);
                    resetForm(values);
                    return fallback(data);
                }
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
                resetForm(values);
                history.push(`/organizations/${organizationUid}/locations`);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, dirty }) => (
                <>
                    <NavigationPrompt when={dirty} />
                    <div className="flex-grow-1 overflow-auto bg-white">
                        <Col>
                            <Container fluid className="py-3">
                                {_isUndefined(fallback) &&
                                    (initialValues.id ? (
                                        <EditPageTitle resource={I18n.t("activerecord.models.location")} />
                                    ) : (
                                        <NewPageTitle resource={I18n.t("activerecord.models.location")} />
                                    ))}
                                <Row>
                                    <Col>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="name">
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.name")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        isInvalid={errors.name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.zip_code")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="zip_code"
                                                        onChange={handleChange}
                                                        value={values.zip_code}
                                                        isInvalid={errors.zip_code}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.zip_code}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location_type.name")}
                                                    </Form.Label>
                                                    <Select
                                                        name="location_type_id"
                                                        className={classNames("react-select", {
                                                            "is-invalid":
                                                                errors.location_type_id || errors.organization_id,
                                                        })}
                                                        classNamePrefix="react-select"
                                                        options={locationTypes}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={(option) => {
                                                            setFieldValue("location_type_id", option.id);
                                                        }}
                                                        onBlur={() => setFieldTouched("location_type_id")}
                                                        value={locationTypes.find(
                                                            (option) => option.id === values.location_type_id,
                                                        )}
                                                        isDisabled={
                                                            values.location_type_required || locationTypes.length === 0
                                                        }
                                                        isLoading={locationTypes.length === 0}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.location_type_id || errors.organization_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.country")}
                                                    </Form.Label>
                                                    <Select
                                                        name="country"
                                                        className={classNames("react-select", {
                                                            "is-invalid": errors.country || errors.organization_id,
                                                        })}
                                                        classNamePrefix="react-select"
                                                        options={countries}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={(option) => {
                                                            setCountry(option.id);
                                                            setFieldValue("state", "");
                                                            setFieldValue("country", option.id);
                                                        }}
                                                        onBlur={() => setFieldTouched("country")}
                                                        value={countries.find((option) => option.id === values.country)}
                                                        isDisabled={countries.length === 0}
                                                        isLoading={countries.length === 0}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.country || errors.organization_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.address_line1")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="address_line1"
                                                        onChange={handleChange}
                                                        value={values.address_line1}
                                                        isInvalid={errors.address_line1 || errors.organization_id}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.address_line1 || errors.organization_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.state")}
                                                    </Form.Label>
                                                    <Select
                                                        name="state"
                                                        className={classNames("react-select", {
                                                            "is-invalid": errors.state,
                                                        })}
                                                        classNamePrefix="react-select"
                                                        options={states}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={(option) => {
                                                            setFieldValue("state", option.id);
                                                        }}
                                                        onBlur={() => setFieldTouched("state")}
                                                        value={
                                                            states.find((option) => option.id === values.state) || []
                                                        }
                                                        isDisabled={statesLoading || states.length === 0}
                                                        isLoading={statesLoading}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.state}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.address_line2")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="address_line2"
                                                        onChange={handleChange}
                                                        value={values.address_line2}
                                                        isInvalid={errors.address_line2 || errors.organization_id}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.address_line2 || errors.organization_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.phone")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="phone"
                                                        onChange={handleChange}
                                                        value={values.phone}
                                                        isInvalid={errors.phone}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.phone}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.city")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="city"
                                                        onChange={handleChange}
                                                        value={values.city}
                                                        isInvalid={errors.city}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.city}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.location.email")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        isInvalid={errors.email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <IndustrySection
                                                parentName=""
                                                errors={errors.industry_relations_attributes || []}
                                                values={values.industry_relations_attributes}
                                                menuPlacement="top"
                                                copyButton={
                                                    <CopyIndustryFromOrganization
                                                        organizationUid={organizationUid}
                                                        field="industry_relations_attributes"
                                                    />
                                                }
                                            />
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </div>
                    <div className="bottom-navigation p-2">
                        <Col>
                            <Container fluid>
                                <div className="d-flex justify-content-between">
                                    <div className="form-actions">
                                        <Button
                                            className="ml-2 text-white"
                                            variant="primary"
                                            disabled={isSubmitting}
                                            onClick={handleSubmit}
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("common.links.save")}
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </div>
                </>
            )}
        </Formik>
    );
};

LocationForm.propTypes = {
    organizationUid: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        location_type_id: PropTypes.number,
        location_type_required: PropTypes.bool.isRequired,
        address_line1: PropTypes.string,
        address_line2: PropTypes.string,
        country: PropTypes.string,
        state: PropTypes.string,
        city: PropTypes.string,
        zip_code: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
    }),
    createOrUpdateOrganizationLocation: PropTypes.func.isRequired,
    fallback: PropTypes.func,
};

export default LocationForm;
