import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import _find from "lodash/find";
import Select from "react-select";
import I18n from "../../../utils/i18n";
import { setAlert } from "../../../state/alert/alertActions";
import NavigationPrompt from "../NavigationPrompt";
import NewPageTitle from "../NewPageTitle";
import EditPageTitle from "../EditPageTitle";
import AvatarContainer from "../AvatarContainer";
import IndustrySection from "../OrganizationForm/sections/IndustrySection";
import { newOrganizationAttorney } from "../../../api/legalApi";
import {
    AttorneyField,
    AttorneyLabel,
    LocationInput,
    AttorneyMultiSelect,
    NotableFields,
    PracticeArea,
    BarDetails,
} from "./inputs";
import TinymceField from "../TinymceField";

const LegalAttorneyForm = ({ organizationUid, attorneyId = null, initialValues, createOrUpdateLegalAttorney }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().max(255).required(),
        last_name: Yup.string().max(255).required(),
        title: Yup.string().max(255).required(),
        graduation_year: Yup.number().min(1900).max(moment().year()).nullable(),
        email: Yup.string().email().max(255).nullable(),
        linkedin_url: Yup.string().url().nullable(),
        location_id: Yup.number().required(),
        law_school: Yup.string().max(100).nullable(),
        overview: Yup.string().sanitizeAndMaxLength(2000).nullable(),
        pro_bono: Yup.string().sanitizeAndMaxLength(1000).nullable(),
        notable_accomplishments_attributes: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().max(100).required(),
                body: Yup.string().sanitizeAndMaxLength(500).nullable(),
            }),
        ),
        notable_cases_attributes: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().max(100).required(),
                body: Yup.string().sanitizeAndMaxLength(500).nullable(),
            }),
        ),
        bar_details_attributes: Yup.array().of(
            Yup.object().shape({
                country: Yup.string().required(),
                state: Yup.string().when("country", {
                    is: "US",
                    then: Yup.string().required(),
                    otherwise: Yup.string(),
                }),
                bar_id: Yup.string().max(15).required(),
            }),
        ),
        industry_relations_attributes: Yup.array()
            .of(
                Yup.object().shape({
                    primary: Yup.boolean().nullable(),
                    industry_id: Yup.string().nullable(),
                }),
            )
            .uniqueProperty("industry_id", I18n.t("errors.messages.taken"))
            .test(
                "at_least_one_primary",
                I18n.t("errors.messages.blank"),
                (items) => items.length === 0 || items.some((item) => item.primary === true),
            ),
        practice_areas_attributes: Yup.array()
            .of(
                Yup.object().shape({
                    industry_id: Yup.string().nullable(),
                }),
            )
            .uniqueProperty("industry_id", I18n.t("errors.messages.taken")),
    });

    const [locationOptions, setLocationOptions] = useState([]);
    const [barOptions, setBarOptions] = useState([]);
    const [courtOptions, setCourtOptions] = useState([]);
    const [languagesOptions, setLanguagesOptions] = useState([]);

    useEffect(() => {
        newOrganizationAttorney(organizationUid).then(({ data }) => {
            setLocationOptions(data.locations);
            setBarOptions(data.bar_admissions);
            setCourtOptions(data.court_admissions);
            setLanguagesOptions(data.languages);
        });
    }, []);

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        createOrUpdateLegalAttorney(organizationUid, attorneyId, { attorney: values })
            .then(() => {
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
                resetForm(values);
                history.push(`/organizations/${organizationUid}/attorneys`);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, setFieldValue, errors, handleSubmit, isSubmitting, dirty }) => (
                <>
                    <NavigationPrompt when={dirty} />
                    <div className="flex-grow-1 overflow-auto bg-white">
                        <Col>
                            <Container fluid className="py-3">
                                {attorneyId ? (
                                    <EditPageTitle resource={I18n.t("activerecord.models.legal/attorney")} />
                                ) : (
                                    <NewPageTitle resource={I18n.t("activerecord.models.legal/attorney")} />
                                )}
                                <Row>
                                    <Col>
                                        <Form>
                                            <Row>
                                                <Col sm={3}>
                                                    <Form.Label>
                                                        {I18n.t("activerecord.attributes.organization.logo")}
                                                    </Form.Label>
                                                    <AvatarContainer inputName="photo" />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.photo}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col sm={9}>
                                                    <AttorneyField name="first_name" />
                                                    <AttorneyField name="last_name" />
                                                    <AttorneyField name="title" />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <AttorneyField name="email" />
                                                    <AttorneyField name="phone" />
                                                </Col>
                                                <Col>
                                                    <AttorneyField name="linkedin_url" />
                                                    <AttorneyField name="timekeeper_id" />
                                                </Col>
                                            </Row>

                                            <LocationInput
                                                organizationUid={organizationUid}
                                                locationOptions={locationOptions}
                                                setLocationOptions={setLocationOptions}
                                            />
                                            <Row>
                                                <Col>
                                                    <AttorneyField name="law_school" />
                                                </Col>
                                                <Col>
                                                    <AttorneyField name="graduation_year" type="number" />
                                                </Col>
                                            </Row>

                                            <Row className="mb-2">
                                                <Col>
                                                    <AttorneyLabel name="bar_details" />
                                                    <BarDetails />
                                                </Col>
                                            </Row>

                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <AttorneyLabel name="practice_area" />
                                                    <PracticeArea />
                                                </Form.Group>
                                            </Form.Row>

                                            <Row>
                                                <Col>
                                                    <AttorneyMultiSelect
                                                        name="attorney_bar_admissions_attributes"
                                                        nestedAttribute="legal_bar_admission_id"
                                                        selectOptions={barOptions}
                                                    />
                                                </Col>
                                            </Row>

                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <AttorneyLabel name="court_admission" />
                                                    <Select
                                                        className="react-select w-100"
                                                        classNamePrefix="react-select"
                                                        onChange={(option) => {
                                                            setFieldValue("legal_court_admission_id", option.id);
                                                        }}
                                                        value={_find(courtOptions, [
                                                            "id",
                                                            values.legal_court_admission_id,
                                                        ])}
                                                        options={courtOptions}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        menuPosition="fixed"
                                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 3 }) }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.legal_court_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <AttorneyLabel name="overview" />
                                                    <TinymceField
                                                        name="overview"
                                                        initTinyMceAttributes={{ height: 300 }}
                                                        maxFieldCount={2000}
                                                        initValue={values.overview}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.overview}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <IndustrySection
                                                        parentName=""
                                                        errors={errors.industry_relations_attributes || []}
                                                        values={values.industry_relations_attributes}
                                                        menuPlacement="top"
                                                        title={
                                                            <Form.Label>
                                                                <AttorneyLabel name="industry_relations_attributes" />
                                                            </Form.Label>
                                                        }
                                                        searchParams={{ legal: true }}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <AttorneyLabel name="notable_accomplishments" />
                                                    <NotableFields name="notable_accomplishments_attributes" />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <AttorneyLabel name="notable_cases" />
                                                    <NotableFields name="notable_cases_attributes" />
                                                </Form.Group>
                                            </Form.Row>
                                            <AttorneyField name="notable_honors" />
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <AttorneyLabel name="pro_bono" />
                                                    <TinymceField
                                                        name="pro_bono"
                                                        initTinyMceAttributes={{ height: 200 }}
                                                        maxFieldCount={1000}
                                                        initValue={values.pro_bono}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.pro_bono}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>

                                            <AttorneyMultiSelect
                                                name="attorney_languages_attributes"
                                                nestedAttribute="language"
                                                selectOptions={languagesOptions}
                                            />
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </div>
                    <div className="bottom-navigation p-2">
                        <Col>
                            <Container fluid>
                                <div className="d-flex justify-content-between">
                                    <div className="form-actions">
                                        <Button
                                            className="ml-2 text-white"
                                            variant="primary"
                                            disabled={isSubmitting}
                                            onClick={handleSubmit}
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />
                                            )}
                                            {I18n.t("common.links.save")}
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </div>
                </>
            )}
        </Formik>
    );
};

LegalAttorneyForm.propTypes = {
    organizationUid: PropTypes.string.isRequired,
    attorneyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValues: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        title: PropTypes.string,
        graduation_year: PropTypes.number,
        practice_areas_attributes: PropTypes.array,
        notable_cases_attributes: PropTypes.array,
        notable_accomplishments_attributes: PropTypes.array,
        attorney_bar_admissions_attributes: PropTypes.array,
        industry_relations_attributes: PropTypes.array,
        attorney_languages_attributes: PropTypes.array,
    }),
    createOrUpdateLegalAttorney: PropTypes.func.isRequired,
};

export default LegalAttorneyForm;
