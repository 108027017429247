import React from "react";
import { FieldArray, getIn, useFormikContext } from "formik";
import _map from "lodash/map";
import { Button, Card, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import _isNull from "lodash/isNull";
import I18n from "../../../../utils/i18n";
import TinymceField from "../../TinymceField";

const NotableFields = ({ name }) => {
    const { values, handleChange, setFieldValue, errors } = useFormikContext();

    return (
        <FieldArray name={name}>
            {({ push, remove }) => (
                <>
                    {_map(values[name], (value, index) =>
                        value._destroy ? (
                            <></>
                        ) : (
                            <Card className="mb-2" key={index}>
                                <Card.Body>
                                    <div className="mb-2">
                                        <div className="d-flex align-items-center">
                                            <Form.Control
                                                type="text"
                                                placeholder={I18n.t(
                                                    "activerecord.attributes.legal/attorney.additional_fields.title",
                                                )}
                                                name={`${name}[${index}][title]`}
                                                onChange={handleChange}
                                                value={value.title}
                                                isInvalid={getIn(errors, `${name}[${index}][title]`)}
                                            />

                                            <Button
                                                variant="outline-secondary"
                                                className="ml-2"
                                                onClick={() => {
                                                    if (_isNull(value.id)) {
                                                        return remove(index);
                                                    } else {
                                                        return setFieldValue(`${name}[${index}][_destroy]`, true);
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            {getIn(errors, `${name}[${index}][title]`)}
                                        </Form.Control.Feedback>
                                    </div>

                                    <TinymceField
                                        placeholder={I18n.t(
                                            "activerecord.attributes.legal/attorney.additional_fields.body",
                                        )}
                                        name={`${name}[${index}][body]`}
                                        initTinyMceAttributes={{ height: 200 }}
                                        maxFieldCount={500}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {getIn(errors, `${name}[${index}][body]`)}
                                    </Form.Control.Feedback>
                                </Card.Body>
                            </Card>
                        ),
                    )}
                    <div className="d-flex">
                        <Button
                            variant="outline-secondary"
                            onClick={() => push({ title: "", body: "", _destroy: false, id: null })}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </div>
                </>
            )}
        </FieldArray>
    );
};

NotableFields.propTypes = {
    name: PropTypes.string.isRequired,
};

export default NotableFields;
