import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import Select from "react-select";
import classNames from "classnames";
import { getIn, useFormikContext } from "formik";
import _isUndefined from "lodash/isUndefined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { getCountryStates } from "../../../../api/countriesApi";
import I18n from "../../../../utils/i18n";

const BarItem = ({ item, index, remove, countries }) => {
    const [country, setCountry] = useState(item.country);
    const [states, setStates] = useState([]);
    const [statesLoading, setStatesLoading] = useState(false);

    const { handleChange, setFieldTouched, setFieldValue, errors } = useFormikContext();

    useEffect(() => {
        if (!_isEmpty(country)) {
            setStatesLoading(true);

            getCountryStates(country)
                .then((response) => setStates(response.data))
                .finally(() => setStatesLoading(false));
        }
    }, [country]);

    return (
        <Card className="mb-2">
            <Card.Body>
                <Row>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>{I18n.t("activerecord.attributes.location.country")}</Form.Label>
                            <Select
                                name={`bar_details_attributes[${index}][country]`}
                                className={classNames("react-select", {
                                    "is-invalid": getIn(errors, `bar_details_attributes[${index}][country]`),
                                })}
                                classNamePrefix="react-select"
                                options={countries}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => {
                                    setCountry(option.id);
                                    setFieldValue(`bar_details_attributes[${index}][state]`, "");
                                    setFieldValue(`bar_details_attributes[${index}][country]`, option.id);
                                }}
                                onBlur={() => setFieldTouched(`bar_details_attributes[${index}][country]`)}
                                value={countries.find((option) => option.id === item.country)}
                                isDisabled={countries.length === 0}
                                isLoading={countries.length === 0}
                            />
                            <Form.Control.Feedback type="invalid">
                                {getIn(errors, `bar_details_attributes[${index}][country]`)}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>{I18n.t("activerecord.attributes.location.state")}</Form.Label>
                            <Select
                                name={`bar_details_attributes[${index}][state]`}
                                className={classNames("react-select", {
                                    "is-invalid": getIn(errors, `bar_details_attributes[${index}][state]`),
                                })}
                                classNamePrefix="react-select"
                                options={states}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => {
                                    setFieldValue(`bar_details_attributes[${index}][state]`, option.id);
                                }}
                                onBlur={() => setFieldTouched(`bar_details_attributes[${index}][state]`)}
                                value={states.find((option) => option.id === item.state) || []}
                                isDisabled={statesLoading || states.length === 0}
                                isLoading={statesLoading}
                            />

                            <Form.Control.Feedback type="invalid">
                                {getIn(errors, `bar_details_attributes[${index}][state]`)}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>{I18n.t("activerecord.attributes.legal/attorney.bar_id")}</Form.Label>

                            <div className="d-flex align-items-center">
                                <Form.Control
                                    type="text"
                                    name={`bar_details_attributes[${index}][bar_id]`}
                                    onChange={handleChange}
                                    value={item.bar_id}
                                    isInvalid={getIn(errors, `bar_details_attributes[${index}][bar_id]`)}
                                />
                                <Button
                                    variant="outline-secondary"
                                    className="ml-2"
                                    onClick={() => {
                                        if (_isUndefined(item.id)) remove(index);
                                        else setFieldValue(`bar_details_attributes[${index}][_destroy]`, true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </div>

                            <Form.Control.Feedback type="invalid">
                                {getIn(errors, `bar_details_attributes[${index}][bar_id]`)}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

BarItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        country: PropTypes.string,
        state: PropTypes.string,
        bar_id: PropTypes.string,
    }).isRequired,
    index: PropTypes.number.isRequired,
    remove: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
};

export default BarItem;
