import React from "react";
import { useFormikContext } from "formik";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _map from "lodash/map";
import _reject from "lodash/reject";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import _findIndex from "lodash/findIndex";
import _forEach from "lodash/forEach";
import _union from "lodash/union";
import PropTypes from "prop-types";
import AttorneyLabel from "./AttorneyLabel";

const AttorneyMultiSelect = ({ selectOptions, name, nestedAttribute }) => {
    const { setFieldValue, values, errors } = useFormikContext();

    const setDestroyFor = (valueIndex, destroy) => {
        setFieldValue(`${name}[${valueIndex}][_destroy]`, destroy);
    };

    const getSelectedOptions = () =>
        _filter(selectOptions, (option) =>
            _includes(_map(_reject(values[name], ["_destroy", true]), nestedAttribute), option.id),
        );

    return (
        <Form.Row>
            <Form.Group as={Col}>
                <AttorneyLabel name={name} />
                <Select
                    isMulti
                    className="react-select w-100"
                    classNamePrefix="react-select"
                    onChange={(option, meta) => {
                        if (meta.action === "remove-value") {
                            const index = _findIndex(values[name], [nestedAttribute, meta.removedValue.id]);
                            setDestroyFor(index, true);
                        }
                        if (meta.action === "clear") {
                            _forEach(meta.removedValues, (value) => {
                                const index = _findIndex(values[name], [nestedAttribute, value.id]);
                                setDestroyFor(index, true);
                            });
                        }
                        if (meta.action === "select-option") {
                            setFieldValue(
                                name,
                                _union(values[name], [{ [nestedAttribute]: meta.option.id, _destroy: false }]),
                            );
                        }
                    }}
                    options={selectOptions}
                    value={getSelectedOptions()}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
                    menuPosition="fixed"
                />
                <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
    );
};

AttorneyMultiSelect.propTypes = {
    selectOptions: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    nestedAttribute: PropTypes.string.isRequired,
};

export default AttorneyMultiSelect;
