import API from "../utils/api";

export function getCompaniesProfile(uid) {
    return API.get(`/companies/profiles/${uid}`);
}

export function getCompaniesProfiles(params) {
    return API.post("/companies/profiles", params);
}

export function getCompaniesFilters() {
    return API.get("/companies/filters");
}

export function getCompaniesAttorneys(uid) {
    return API.get(`/companies/profiles/${uid}/attorneys`);
}

export function getAttorney(uid) {
    return API.get(`/companies/attorneys/${uid}`);
}

export function pinFilter(params) {
    return API.post("/personal_settings", params);
}

export function unpinFilter(params) {
    return API.delete("/personal_settings", { data: params });
}
