import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import I18n from "../../../../utils/i18n";

const AttorneyLabel = ({ name }) => <Form.Label>{I18n.t(`activerecord.attributes.legal/attorney.${name}`)}</Form.Label>;

AttorneyLabel.propTypes = {
    name: PropTypes.string.isRequired,
};
export default AttorneyLabel;
