import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import _map from "lodash/map";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import AttorneyCard from "./attorneys/AttorneyCard";
import { getCompaniesAttorneys } from "../../../api/companiesApi";

const Attorneys = ({ uid }) => {
    const [attorneys, setAttorneys] = useState([]);

    useEffect(() => {
        getCompaniesAttorneys(uid).then(({ data }) => setAttorneys(data.records));
    }, [uid]);

    return (
        <Card className="mb-2 border-top-0">
            <Card.Body>
                <Row>
                    {_map(attorneys, (attorney) => (
                        <Col sm={6} key={attorney.id}>
                            <AttorneyCard attorney={attorney} />
                        </Col>
                    ))}
                </Row>
            </Card.Body>
        </Card>
    );
};

Attorneys.propTypes = {
    uid: PropTypes.string.isRequired,
};

export default Attorneys;
