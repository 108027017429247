import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Accordion, AccordionCollapse, AccordionToggle } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _find from "lodash/find";
import I18n from "../../../../utils/i18n";

const IndustriesList = ({ industries, showMoreKey = undefined, name = undefined }) => {
    const primaryIndustry = _find(industries, "primary");
    const secondaryIndustries = _filter(industries, (industry) => !industry.primary);

    if (industries.length === 0) {
        return null;
    }

    return (
        <div>
            {primaryIndustry && (
                <Row className="mt-3">
                    <Col className={classNames({ "font-weight-bold": primaryIndustry.primary })}>
                        {I18n.t("companies.profiles.paragraphs.industries.primary_name", {
                            name: primaryIndustry.name,
                        })}
                    </Col>
                </Row>
            )}
            {secondaryIndustries.length > 0 && (
                <Accordion className="mt-3">
                    <AccordionToggle eventKey="industries" as={Link} to="#">
                        {I18n.t(showMoreKey || "common.links.show_more", {
                            name: name || I18n.t("activerecord.models.industry.other"),
                            count: secondaryIndustries.length,
                        })}
                    </AccordionToggle>
                    <AccordionCollapse eventKey="industries">
                        <div>
                            {_map(secondaryIndustries, (industry, index) => (
                                <Row key={index} className="mt-3">
                                    <Col>{industry.name}</Col>
                                </Row>
                            ))}
                        </div>
                    </AccordionCollapse>
                </Accordion>
            )}
        </div>
    );
};

IndustriesList.propTypes = {
    industries: PropTypes.arrayOf(
        PropTypes.shape({
            primary: PropTypes.bool,
            name: PropTypes.string,
            area: PropTypes.string,
            focus: PropTypes.string,
            specialty: PropTypes.string,
        }),
    ),
    showMoreKey: PropTypes.string,
    name: PropTypes.string,
};

export default IndustriesList;
