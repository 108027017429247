import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import _isUndefined from "lodash/isUndefined";
import _flatten from "lodash/flatten";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { createOrganizationLocation } from "../../../api/locationsApi";
import LocationForm from "./index";

const LocationModal = ({ organizationUid, show, handleClose, setLocationOptions }) => {
    const [location, setLocation] = useState();
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (_isUndefined(location)) return;

        setLocationOptions((prev) => _flatten([...prev, location]));
        setFieldValue("location_id", location.id);
        handleClose();
    }, [location]);
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LocationForm
                    organizationUid={organizationUid}
                    initialValues={{
                        name: "",
                        location_type_id: null,
                        location_type_required: false,
                        address_line1: "",
                        address_line2: "",
                        country: "",
                        state: "",
                        city: "",
                        zip_code: "",
                        phone: "",
                        email: "",
                        industry_relations_attributes: [],
                    }}
                    createOrUpdateOrganizationLocation={createOrganizationLocation}
                    fallback={setLocation}
                />
            </Modal.Body>
        </Modal>
    );
};

LocationModal.propTypes = {
    organizationUid: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    setLocationOptions: PropTypes.func.isRequired,
};

export default LocationModal;
