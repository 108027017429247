import React, { useEffect, useState } from "react";
import _map from "lodash/map";
import { Button } from "react-bootstrap";
import { FieldArray, useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { getCountries } from "../../../../api/countriesApi";
import BarItem from "./BarItem";

const BarDetails = () => {
    const { values } = useFormikContext();
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries().then((response) => setCountries(response.data));
    }, []);

    return (
        <FieldArray name="bar_details_attributes">
            {({ push, remove }) => (
                <>
                    {_map(values.bar_details_attributes, (item, index) =>
                        item._destroy === true ? (
                            <></>
                        ) : (
                            <BarItem key={index} item={item} index={index} remove={remove} countries={countries} />
                        ),
                    )}
                    <div className="d-flex">
                        <Button
                            variant="outline-secondary"
                            onClick={() => push({ country: "", state: "", bar_id: "" })}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </div>
                </>
            )}
        </FieldArray>
    );
};

export default BarDetails;
