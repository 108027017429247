import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Button } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/pro-solid-svg-icons";
import Select from "react-select";
import _findIndex from "lodash/findIndex";
import _map from "lodash/map";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _filter from "lodash/filter";
import { FieldArray, useFormikContext } from "formik";
import IndustryInput from "./IndustryInput";
import I18n from "../../../../utils/i18n";

const IndustrySection = ({
    parentName = "organization",
    values,
    errors,
    menuPlacement,
    tip,
    copyButton,
    title = undefined,
    searchParams = {},
}) => {
    const { setFieldValue } = useFormikContext();

    const hideIndustry = (value, index) => {
        if (!value._destroy) {
            const industryIndex = _findIndex(
                values,
                (industry) => industry.focus === value.focus && _isEmpty(industry.specialty) && !industry._destroy,
            );

            return industryIndex > -1 && !_isEmpty(value.specialty) && industryIndex !== index;
        } else return true;
    };

    const destroyIndustry = (index) => {
        setFieldValue(`${parentName}[industry_relations_attributes][${index}][_destroy]`, true);
        setFieldValue(`${parentName}[industry_relations_attributes][${index}][primary]`, false);
    };

    useEffect(() => {
        if (
            _filter(values, (industry) => !industry._destroy).length === 1 &&
            _findIndex(values, (industry) => industry.primary && !industry._destroy) < 0
        ) {
            const industryIndex = _findIndex(
                values,
                (industry) => !industry._destroy && _isNumber(industry.industry_id),
            );
            setFieldValue(`${parentName}[industry_relations_attributes][${industryIndex}][primary]`, true);
        }
    }, [values]);

    return (
        <Row>
            <Col>
                <Form.Group>{title || <h3>{I18n.t("activerecord.models.industry.one")}</h3>}</Form.Group>

                <FieldArray name={`${parentName}[industry_relations_attributes]`}>
                    {({ push, remove }) => (
                        <>
                            {_map(values, (value, index) => (
                                <Form.Row
                                    key={index}
                                    className={classNames("align-items-baseline", {
                                        "d-none": hideIndustry(value, index),
                                    })}
                                >
                                    <Form.Group className="col">
                                        <IndustryInput
                                            parentName={parentName}
                                            errors={Array.isArray(errors) ? errors : []}
                                            value={value}
                                            menuPlacement={menuPlacement}
                                            index={index}
                                            push={push}
                                            values={values}
                                            searchParams={searchParams}
                                        />

                                        <div className="invalid-feedback">
                                            {Array.isArray(errors) && errors[index] && errors[index].industry_id}
                                        </div>
                                    </Form.Group>

                                    {!value.primary && (
                                        <Form.Group className="col-auto">
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    if (_isNumber(value.industry_id)) {
                                                        if (_isEmpty(value.specialty)) {
                                                            _map(
                                                                values,
                                                                (industry, i) =>
                                                                    industry.focus === value.focus &&
                                                                    destroyIndustry(i),
                                                            );
                                                        } else destroyIndustry(index);
                                                    } else {
                                                        remove(index);
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </Form.Group>
                                    )}
                                </Form.Row>
                            ))}
                            {copyButton}
                            <Form.Group>
                                <Button variant="outline-secondary" onClick={() => push({ industry_id: "" })}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    <strong>{I18n.t("wizard.steps.industry.select")}</strong>
                                </Form.Label>
                                <Select
                                    className={classNames("react-select", {
                                        "is-invalid": errors && !Array.isArray(errors),
                                    })}
                                    classNamePrefix="react-select"
                                    options={_filter(values, (v) => !v._destroy && _isNumber(v.industry_id))}
                                    value={
                                        _find(values, (i) => i.primary && !i._destroy && _isNumber(i.industry_id)) ||
                                        null
                                    }
                                    getOptionValue={(industry) => industry.industry_id}
                                    getOptionLabel={(industry) => industry.name}
                                    menuPlacement={menuPlacement}
                                    onChange={(option) => {
                                        _map(values, (value, index) =>
                                            setFieldValue(
                                                `${parentName}[industry_relations_attributes][${index}][primary]`,
                                                value.industry_id === option.industry_id,
                                            ),
                                        );
                                    }}
                                />
                                {tip && (
                                    <Form.Label>
                                        <small>{tip}</small>
                                    </Form.Label>
                                )}
                                <div className="invalid-feedback">{!Array.isArray(errors) && errors}</div>
                            </Form.Group>
                        </>
                    )}
                </FieldArray>
            </Col>
        </Row>
    );
};

IndustrySection.propTypes = {
    parentName: PropTypes.string,
    values: PropTypes.array.isRequired,
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    menuPlacement: PropTypes.string.isRequired,
    tip: PropTypes.string,
    copyButton: PropTypes.node,
    title: PropTypes.node,
    searchParams: PropTypes.object,
};

export default IndustrySection;
