import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import _find from "lodash/find";
import _compact from "lodash/compact";
import _toString from "lodash/toString";
import Avatar from "../../../../common/components/Avatar";
import I18n from "../../../../utils/i18n";
import AddCompanyToListsModal from "../../../CompanyListsPage/components/AddCompanyToListsModal";
import RegularAddToListButton from "../../../CompanyListsPage/components/RegularAddToListButton";
import SolidAddToListButton from "../../../CompanyListsPage/components/SolidAddToListButton";
import FormattedScore from "../../../../common/components/FormattedScore";
import AddToProjectAction from "../AddToProjectActions";
import AttorneysSection from "./AttorneysSection";

const ProfileCard = ({
    id,
    uid,
    currency_id,
    name,
    logo_url,
    updated_at,
    jb_score,
    total_score,
    company_structure,
    industries,
    certificates,
    locations,
    present_in_lists,
    legal,
    attorneys,
}) => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const primaryIndustry = _find(industries, ["primary", true]);
    const primaryCertificate = _find(certificates, ["primary", true]);
    const requiredLocation = _find(locations, ["location_type_required", true]);
    const [showAddListModal, setShowAddListModal] = useState(false);

    return (
        <Card className="mb-3">
            <Card.Body>
                <Container>
                    <Row>
                        <Col sm={2} className="d-flex align-items-center justify-content-center">
                            <Avatar preview={logo_url} name={name} />
                        </Col>
                        <Col sm={6}>
                            <div>
                                <h3>
                                    {currentActor.organization.permissions.company_profile_permission ? (
                                        <Link to={`/companies/profiles/${uid}`}>{name}</Link>
                                    ) : (
                                        name
                                    )}
                                </h3>
                                {requiredLocation && (
                                    <div>
                                        {_compact([
                                            requiredLocation.city,
                                            requiredLocation.state?.name,
                                            requiredLocation.country?.name,
                                        ]).join(" ")}
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col sm={2} />
                        <Col sm={2}>
                            {(jb_score || total_score) && (
                                <FormattedScore jbScore={_toString(jb_score)} totalScore={_toString(total_score)} />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col />
                        <Col>
                            {company_structure.doing_business_as && (
                                <div>
                                    <small className="text-muted">
                                        {I18n.t("activerecord.attributes.company_structure.doing_business_as")}
                                    </small>
                                    <div>{company_structure.doing_business_as}</div>
                                </div>
                            )}
                        </Col>
                        <Col>
                            {primaryIndustry && (
                                <div>
                                    <small className="text-muted">{I18n.t("activerecord.models.industry.one")}</small>
                                    <div>{`${primaryIndustry.area}: ${primaryIndustry.focus}`}</div>
                                </div>
                            )}
                        </Col>
                        <Col>
                            {primaryCertificate && (
                                <div>
                                    <small className="text-muted">
                                        {I18n.t("activerecord.models.diversity/certificate")}
                                    </small>
                                    <div>{primaryCertificate.name}</div>
                                </div>
                            )}
                        </Col>
                        <Col>
                            {company_structure && currency_id && (
                                <div>
                                    <small className="text-muted">
                                        {I18n.t("activerecord.models.sales_indicator")}
                                    </small>
                                    <div>
                                        {company_structure.sales.toLocaleString(I18n.locale, {
                                            style: "currency",
                                            currency: currency_id,
                                        })}
                                    </div>
                                </div>
                            )}
                        </Col>
                        <Col>
                            {company_structure && (
                                <div>
                                    <small className="text-muted">
                                        {I18n.t("activerecord.models.employees_indicator")}
                                    </small>
                                    <div>{company_structure.employees}</div>
                                </div>
                            )}
                        </Col>
                    </Row>

                    {legal && (
                        <AttorneysSection
                            organizationId={uid}
                            filteredAttorneys={attorneys.filtered_attorneys.records}
                            attorneys={attorneys.records}
                        />
                    )}
                </Container>
            </Card.Body>
            <Card.Footer className="text-muted">
                <Row className="justify-content-between">
                    <Col sm={3} className="align-content-center">
                        {I18n.t("companies.profiles.text.updated", { date: moment(updated_at).fromNow() })}
                    </Col>
                    <Col sm={6}>
                        {currentActor.organization.uid !== uid && (
                            <div className="d-flex justify-content-end">
                                <AddToProjectAction organizationId={id} />

                                {present_in_lists ? (
                                    <SolidAddToListButton onClick={() => setShowAddListModal(true)} />
                                ) : (
                                    <RegularAddToListButton onClick={() => setShowAddListModal(true)} />
                                )}

                                <AddCompanyToListsModal
                                    organizationUid={uid}
                                    onClose={() => setShowAddListModal(false)}
                                    show={showAddListModal}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

ProfileCard.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    uid: PropTypes.string.isRequired,
    currency_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    logo_url: PropTypes.string,
    present_in_lists: PropTypes.bool.isRequired,
    jb_score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    company_structure: PropTypes.shape({
        doing_business_as: PropTypes.string,
        employees: PropTypes.number,
        sales: PropTypes.number,
    }),
    industries: PropTypes.arrayOf(
        PropTypes.shape({
            primary: PropTypes.bool,
            area: PropTypes.string,
        }),
    ),
    certificates: PropTypes.arrayOf(
        PropTypes.shape({
            primary: PropTypes.bool,
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    ),
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            country: PropTypes.shape({
                name: PropTypes.string,
                code: PropTypes.string,
            }),
            state: PropTypes.shape({
                name: PropTypes.string,
                code: PropTypes.string,
            }),
            city: PropTypes.string,
            location_type_required: PropTypes.bool,
        }),
    ),
    legal: PropTypes.bool,
    attorneys: PropTypes.shape({
        records: PropTypes.array,
        filtered_attorneys: PropTypes.shape({
            records: PropTypes.array,
        }),
    }),
};

export default ProfileCard;
