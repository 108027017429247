import React, { useState } from "react";
import { Accordion, Card, Col, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import _map from "lodash/map";
import I18n from "../../../../utils/i18n";
import AttorneyCard from "../../../CompanyProfilePage/components/attorneys/AttorneyCard";

const AttorneysSection = ({ organizationId, filteredAttorneys, attorneys }) => {
    const [open, setOpen] = useState(false);
    const [viewAllValue, setViewAll] = useState(false);

    return (
        <Card className="mt-2">
            <Accordion activeKey={open ? `company-attorneys-${organizationId}` : ""}>
                <Accordion.Toggle
                    as="div"
                    eventKey={`company-attorneys-${organizationId}`}
                    onClick={() => setOpen((prevState) => !prevState)}
                >
                    <Card.Header style={{ borderBottom: "none" }}>
                        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} className="mr-2 arrow" />
                        <strong>{I18n.t("forms.organization.edit.tabs.attorneys")}</strong> {filteredAttorneys.length}/
                        {attorneys.length}
                    </Card.Header>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={`company-attorneys-${organizationId}`}>
                    <Card.Body>
                        <Row className="mb-2">
                            <Col>
                                <Form.Check
                                    type="switch"
                                    checked={viewAllValue}
                                    onChange={() => setViewAll((prevState) => !prevState)}
                                    id={`${organizationId}-view-all`}
                                    label={I18n.t("activerecord.attributes.legal/attorney.view_all")}
                                />
                            </Col>
                        </Row>

                        <Row>
                            {_map(viewAllValue ? attorneys : filteredAttorneys, (attorney) => (
                                <Col sm={6} key={attorney.id}>
                                    <AttorneyCard attorney={attorney} />
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>
        </Card>
    );
};

AttorneysSection.propTypes = {
    organizationId: PropTypes.string.isRequired,
    attorneys: PropTypes.array.isRequired,
    filteredAttorneys: PropTypes.array.isRequired,
};

export default AttorneysSection;
