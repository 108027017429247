import API, { buildFormData } from "../utils/api";

export function getOrganizationAttorneys(organizationUid, params) {
    return API.get(`/organizations/${organizationUid}/legal/attorneys`, { params: params });
}

export function createOrganizationAttorney(organizationUid, values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.post(`/organizations/${organizationUid}/legal/attorneys`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function newOrganizationAttorney(organizationUid) {
    return API.get(`/organizations/${organizationUid}/legal/attorneys/new`);
}

export function updateOrganizationAttorney(organizationUid, attorneyId, values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.patch(`/organizations/${organizationUid}/legal/attorneys/${attorneyId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function editOrganizationAttorney(organizationUid, attorneyId) {
    return API.get(`/organizations/${organizationUid}/legal/attorneys/${attorneyId}/edit`);
}

export function deleteOrganizationAttorney(organizationUid, attorneyId) {
    return API.delete(`/organizations/${organizationUid}/legal/attorneys/${attorneyId}`);
}

export function getAttorneyCases(organizationUid, attorneyId, params) {
    return API.get(`/organizations/${organizationUid}/legal/attorneys/${attorneyId}/cases`, {
        params: params,
    });
}

export function createAttorneyCase(organizationUid, attorneyId, values) {
    return API.post(`/organizations/${organizationUid}/legal/attorneys/${attorneyId}/cases`, values);
}

export function updateAttorneyCase(organizationUid, attorneyId, caseId, values) {
    return API.patch(`/organizations/${organizationUid}/legal/attorneys/${attorneyId}/cases/${caseId}`, values);
}

export function editAttorneyCase(organizationUid, attorneyId, caseId) {
    return API.get(`/organizations/${organizationUid}/legal/attorneys/${attorneyId}/cases/${caseId}/edit`);
}

export function deleteAttorneyCase(organizationUid, attorneyId, caseId) {
    return API.delete(`/organizations/${organizationUid}/legal/attorneys/${attorneyId}/cases/${caseId}`);
}

export function getRoles() {
    return API.get(`/legal/roles`);
}
