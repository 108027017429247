import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row, Tabs, Tab } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { GeneralInfo, Locations, IndustrySpecificQuestions, Users, Header, Attorneys } from "./components";
import { getCompaniesProfile } from "../../api/companiesApi";
import I18n from "../../utils/i18n";

const CompanyProfilePage = () => {
    const { uid } = useParams();
    const [data, setData] = useState(null);
    const [key, setKey] = useState("general");
    const currentActor = useSelector((state) => state.currentActor.resource);
    const currentUser = useSelector((state) => state.currentUser.resource);

    useEffect(() => {
        getCompaniesProfile(uid).then((response) => setData(response.data));
    }, []);

    return (
        <>
            {data ? (
                <>
                    <Col sm={12} className="scrollable full-scrollable">
                        <Header currentActor={currentActor} currentUser={currentUser} values={data} uid={uid} />
                        <Row>
                            <Col sm={1} />
                            <Col>
                                <Tabs defaultActiveKey="general" activeKey={key} onSelect={(k) => setKey(k)}>
                                    <Tab eventKey="general" title={I18n.t("companies.profiles.tabs.general")}>
                                        <GeneralInfo data={data} />
                                    </Tab>

                                    {!_isEmpty(data.locations) && (
                                        <Tab
                                            eventKey="locations"
                                            title={I18n.t("forms.organization.edit.tabs.locations")}
                                        >
                                            <Locations locations={data.locations} />
                                        </Tab>
                                    )}

                                    <Tab
                                        eventKey="industrySpecificQuestions"
                                        title={I18n.t("companies.profiles.tabs.industry_specific_questions")}
                                    >
                                        <div></div>
                                        <IndustrySpecificQuestions uid={uid} />
                                    </Tab>

                                    {data.show_legal_tab && (
                                        <Tab
                                            eventKey="attorneys"
                                            title={I18n.t("forms.organization.edit.tabs.attorneys")}
                                        >
                                            <Attorneys uid={uid} />
                                        </Tab>
                                    )}

                                    {((currentActor.organization.uid === uid &&
                                        (currentActor.profile_manager || currentActor.super_manager)) ||
                                        currentUser.superadmin ||
                                        currentUser.admin) && (
                                        <Tab eventKey="users" title={I18n.t("forms.organization.edit.tabs.users")}>
                                            <Users uid={uid} />
                                        </Tab>
                                    )}
                                </Tabs>
                            </Col>
                            <Col sm={1} />
                        </Row>
                    </Col>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default CompanyProfilePage;
